import { Link } from "react-router-dom";

const Navbar = (loggedIn) => {
	return (
		<div className="navbar">
			<Link to="/" className="brand">
				Logonz
			</Link>
			<div className="center-items">{loggedIn ? <Link>Dashboard</Link> : ""}</div>
			<Link to="/login" className="login">
				Login
			</Link>
		</div>
	);
};

export default Navbar;
