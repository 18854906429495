import React from "react";

const Welcome = () => {
	return (
		<>
			<div className="welcome">
				<img src="https://i.ibb.co/Zcm7KZQ/Undraw-Passman.png" alt="undraw-Logonz" />
				<h1>Password Manager</h1>
			</div>

			<div className="products">
				<div className="top">
					<div className="item">
						<h3>Firefox Extension</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default Welcome;
