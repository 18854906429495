import "./App.css";
import Navbar from "./components/Navbar.component";
import Welcome from "./components/Welcome.component";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";

function App() {
	const [loggedIn, setLoggedIn] = useState(true);

	return (
		<Router>
			<div className="App">
				<Navbar />
				<Switch>
					<Route path="/">
						<Welcome loggedIn={loggedIn} />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
